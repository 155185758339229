import React from 'react';
import Header from '../Common/Header6';
import Footer6 from '../Common/Footer3';
import Slider6 from '../Segments/SliderProjectDetail';
import ProjectDetailContent from '../Segments/ProjectDetailContent';

class ProjectDetail6 extends React.Component {
    
    render() {
        return (
            <>
                <Header />
              
               
                    
                    <ProjectDetailContent />
           

                <Footer6 />
            </>
        );
    };
};

export default ProjectDetail6;